// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["vC_5_Q9aP", "GEwKAaPfS", "ED5Ke5jwh"];

const variantClassNames = {ED5Ke5jwh: "framer-v-1mrsi3v", GEwKAaPfS: "framer-v-1vcocbr", vC_5_Q9aP: "framer-v-1fveetk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "vC_5_Q9aP", "Variant 2": "GEwKAaPfS", "Variant 3": "ED5Ke5jwh"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "vC_5_Q9aP"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "vC_5_Q9aP", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppeari30uzu = activeVariantCallback(async (...args) => {
await delay(() => setVariant("GEwKAaPfS"), 1000)
})

const onAppear13pe1an = activeVariantCallback(async (...args) => {
await delay(() => setVariant("ED5Ke5jwh"), 1000)
})

const onAppearuwan3u = activeVariantCallback(async (...args) => {
await delay(() => setVariant("vC_5_Q9aP"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppeari30uzu, ED5Ke5jwh: onAppearuwan3u, GEwKAaPfS: onAppear13pe1an})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-G2SSV", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1fveetk", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"vC_5_Q9aP"} ref={ref} style={{...style}} {...addPropertyOverrides({ED5Ke5jwh: {"data-framer-name": "Variant 3"}, GEwKAaPfS: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 2010, intrinsicWidth: 3600, pixelHeight: 2010, pixelWidth: 3600, sizes: "1200px", src: new URL("assets/fbGK3cNM6BHQMr8U7DsXWKE.png", import.meta.url).href, srcSet: `${new URL("assets/512/fbGK3cNM6BHQMr8U7DsXWKE.png", import.meta.url).href} 512w, ${new URL("assets/1024/fbGK3cNM6BHQMr8U7DsXWKE.png", import.meta.url).href} 1024w, ${new URL("assets/2048/fbGK3cNM6BHQMr8U7DsXWKE.png", import.meta.url).href} 2048w, ${new URL("assets/fbGK3cNM6BHQMr8U7DsXWKE.png", import.meta.url).href} 3600w`}} className={"framer-p25xv5"} layoutDependency={layoutDependency} layoutId={"X_cyZMovT"} {...addPropertyOverrides({GEwKAaPfS: {background: {alt: "", fit: "fill", intrinsicHeight: 2010, intrinsicWidth: 3600, pixelHeight: 2010, pixelWidth: 3600, sizes: "1200px", src: new URL("assets/Cf93VPwhaGo1WNzE0ejA2CKASwY.png", import.meta.url).href, srcSet: `${new URL("assets/512/Cf93VPwhaGo1WNzE0ejA2CKASwY.png", import.meta.url).href} 512w, ${new URL("assets/1024/Cf93VPwhaGo1WNzE0ejA2CKASwY.png", import.meta.url).href} 1024w, ${new URL("assets/2048/Cf93VPwhaGo1WNzE0ejA2CKASwY.png", import.meta.url).href} 2048w, ${new URL("assets/Cf93VPwhaGo1WNzE0ejA2CKASwY.png", import.meta.url).href} 3600w`}}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-G2SSV [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G2SSV .framer-j8m6sx { display: block; }", ".framer-G2SSV .framer-1fveetk { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-G2SSV .framer-p25xv5 { flex: none; height: 670px; overflow: visible; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-G2SSV .framer-1fveetk { gap: 0px; } .framer-G2SSV .framer-1fveetk > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-G2SSV .framer-1fveetk > :first-child { margin-top: 0px; } .framer-G2SSV .framer-1fveetk > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 670
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"GEwKAaPfS":{"layout":["auto","auto"]},"ED5Ke5jwh":{"layout":["auto","auto"]}}}
 */
const FramerpvIlr08fC: React.ComponentType<Props> = withCSS(Component, css, "framer-G2SSV") as typeof Component;
export default FramerpvIlr08fC;

FramerpvIlr08fC.displayName = "gradient";

FramerpvIlr08fC.defaultProps = {height: 670, width: 1200};

addPropertyControls(FramerpvIlr08fC, {variant: {options: ["vC_5_Q9aP", "GEwKAaPfS", "ED5Ke5jwh"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerpvIlr08fC, [])